export const API_ROOT = 'https://api.fluid.services';
//export const API_ROOT = 'http://api.fluid.localhost';
// export const API_ROOT = 'https://api.fluid.dev4.stream9.net';

export const AVATAR_PATH_USER = API_ROOT + '/uploaded/users/images/';
export const AVATAR_PATH_ORG = API_ROOT + '/uploaded/organizations/images/';
export const AVATAR_PATH_STORE_FRONT = API_ROOT + '/uploaded/store-fronts/images/';
export const AVATAR_PATH_SURVEY_WEBSITE = API_ROOT + '/uploaded/survey-websites/images/';
export const IMAGE_PATH_INTEGRATION = API_ROOT + '/uploaded/integrations/images/';
export const AVATAR_DEFAULT = API_ROOT + '/assets/images/default-avatar.svg';
export const AVATAR_MESSENGER = API_ROOT + '/assets/images/messenger-avatar.svg';
export const IMPORT_TEMPLATE_PATH_JOBS = API_ROOT + '/assets/csv-templates/jobs-import.csv';
export const IMPORT_TEMPLATE_PATH_USERS = API_ROOT + '/assets/csv-templates/users-import.csv';
export const SERVICE_TERRITORIES_FILE_PATH = API_ROOT + '/uploaded/organizations/service-territories/';
export const MESSAGE_ATTACHMENT_FILE_PATH =   API_ROOT + '/uploaded/message-attachments/';

export const API_ASSETS = API_ROOT + '/assets/images/';

export const JOB_STATUS_COMPLETED_ID = 3;

export const JOB_STATUS_COLORS = {
  'in-progress': '#F7C700',
  assigned: '#0065DB',
  completed: '#47CC8A',
};

export const ORGANIZATION_TYPE_IDS = {
  FLUID: 1,
  PARTNER: 2,
  CLIENT: 3,
};

export const CLIENT_ADMIN_ROLE_ID = 6;
export const CLIENT_ACCOUNT_OWNER_ROLE_ID = 7;
export const PARTNER_ACCOUNT_OWNER_ROLE_ID = 10;

export const STRIPE_PUBLIC_KEY = 'pk_live_jbR2CcDzf5yZV7QQ5R5E7NZr';
// export const STRIPE_PUBLIC_KEY = 'pk_test_6YoNy4a8lkIlzvAm976yfmNI';

export const USA_LAT_LANG_CENTER = {
  // Chicago
  lat: 41.850033,
  lng: -87.6500523,
};

export const THIRD_PARTY_REVIEW_ICONS = {
  google: 'google.png',
  facebook: 'facebook.png',
};

export const GOOGLE_API_KEY = 'AIzaSyAtwbOXe-GwTawi2X6wp8-1eAnqbzqNtoM';

export const FLUID_ROLE_IDS = ['3', '4'];

export const FLUID_ADMIN_ROLE_ID = '4';

export const IMPORT_CONFIGURATOR_DESCRIPTION =
  'Please match up the columns of your import with the fields available in Fluid Local.  If you have any questions, reach out to the Fluid team using the chat in the lower right corner.';

export const FREE_PLAN_FEATURES = [
  '1 User',
  'Access to Wordpress Plugin to Embed the Following on your Wordpress Website',
  'Embed any existing Fluid Reviews and Job Posts',
  'Embed third party Reviews, including Google, Facebook, BBB, Yelp, and more'
];
