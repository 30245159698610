import Http from "../utilities/Http";
import {API_ROOT} from "../config";

export default {
    async onBoard(user, organization, googlePlace, teamMembers, cardToken) {
        let response = await Http().post(API_ROOT + '/on-boarding/on-board', {
            user,
            organization,
            // googlePlace,
            teamMembers,
            cardToken
        });

        return response.data;
    }
};
