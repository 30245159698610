import React, {useState, useEffect} from 'react';
import {View, StyleSheet, ScrollView, Dimensions} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import EStyleSheet from 'react-native-extended-stylesheet';

import {setUser, setToken, setOrganization, setOrganizationRoleId, resetState, setPermissions} from '../store/auth/authActions';
import AppText from '../components/AppText';
import AppButton from '../components/AppButton';
import UnauthenticatedHeader from '../components/UnauthenticatedHeader';
import {basicStyles} from '../styles/basic';
import UserInvitesService from '../services/UserInvitesService';
import Avatar from '../components/Avatar';
import {CLIENT_ACCOUNT_OWNER_ROLE_ID, AVATAR_DEFAULT, AVATAR_PATH_ORG, FREE_PLAN_FEATURES} from '../config';
import FieldSet from '../components/FieldSet';
import AuthService from '../services/AuthService';
import AccountSetupHeader from '../components/AccountSetupHeader';
import LocationsService from '../services/LocationsService';
import SubscriptionTypesService from '../services/SubscriptionTypesService';
import StripePaymentSubscription from '../components/StripePaymentSubscription';
import OrganizationsService from '../services/OrganizationsService';
import {setWarning} from '../store/warning/warningActions';
import {setProcessingIndicatorActive} from '../store/miscellaneous/miscellaneousActions';
import SubscriptionSelector from "../components/SubscriptionSelector";
import TouchView from "../components/TouchView";
import SubscriptionSelectorsContainer from "../components/SubscriptionSelectorsContainer";

function AccountSetup(props) {
    const [state, setState] = useState({
        userOrganization: {},
        hasPassword: false,
        errorMessage: null,
        currentSectionIndex: 0,
    });
    const [states, setStates] = useState([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [header1Height, setHeader1Height] = useState(0);
    const [header2Height, setHeader2Height] = useState(0);
    const [inviteFieldChunkSize, setInviteFieldChunkSize] = useState(2);
    const [companyInfoChunkSize, setCompanyInfoChunkSize] = useState(3);
    const {inviteCode} = props.route.params;
    const {navigate} = props.navigation;
    const {setToken, setUser, resetState, setOrganization, setOrganizationRoleId, setWarning, setProcessingIndicatorActive, setPermissions} = props;

    const updateUserOrganization = (prop, value) => {
        setState(prevState => {
            return {...prevState, userOrganization: {...prevState.userOrganization, [prop]: value}};
        });
    };

    async function acceptInvite() {
        if (state.userOrganization.user_email && state.userOrganization.user_password && (state.hasPassword || state.userOrganization.confirm_password)) {
            try {
                const data = await UserInvitesService.acceptInvite(inviteCode, state.userOrganization);
                const {token, user, organization, role_id, userOrganization} = data;

                await AsyncStorage.setItem('authToken', token);
                await AsyncStorage.setItem('organizationId', organization.organization_id);
                await AsyncStorage.setItem('roleId', role_id);

                setState(prevState => {
                    return {...prevState, userOrganization: {...prevState.userOrganization, ...userOrganization}}
                });

                setToken(token);
                setUser(user);
                setOrganization(organization);
                setOrganizationRoleId(role_id);

                if (role_id != CLIENT_ACCOUNT_OWNER_ROLE_ID) {
                    navigate('Authenticated');
                }
            } catch (error) {
                console.log('error: ', error);
                setState(prevState => {
                    return {...prevState, errorMessage: error?.response?.data?.meta?.message};
                });
            }
        }
    }

    const fields = [
        {
            type: 'text',
            label: 'Email',
            onChangeText: value => updateUserOrganization('user_email', value),
            value: state.userOrganization.user_email,
        },
        {
            type: 'text',
            label: 'Password',
            onChangeText: value => updateUserOrganization('user_password', value),
            value: state.userOrganization.user_password,
            secureTextEntry: true,
            ...(!state.hasPassword ? {width: '50%', style: {marginBottom: 0}} : {}),
        },
    ];

    if (!state.hasPassword) {
        fields.push({
            type: 'text',
            label: 'Confirm Password',
            onChangeText: value => updateUserOrganization('confirm_password', value),
            value: state.userOrganization.confirm_password,
            secureTextEntry: true,
            width: '50%',
        });
    }

    function incrementCurrentSectionIndex() {
        setState(prevState => {
            return {...prevState, currentSectionIndex: prevState.currentSectionIndex + 1};
        });
    }

    function getScreenMaxHeight() {
        const windowHeight = Dimensions.get('window').height;

        return windowHeight - (header1Height + header2Height);
    }

    async function freeSubmit() {
        setProcessingIndicatorActive(true);
        let organization = {...state.userOrganization};
        try {
            organization = await OrganizationsService.updateOrganization(organization.organization_id, organization);
        } catch (e) {
            console.log('error updating org: ', e);
            setProcessingIndicatorActive(false);
            return
        }

        setProcessingIndicatorActive(false);

        setWarning({
            confirmAction() {
            },
            confirmLabel: 'Continue',
            active: true,
            warningMessage: 'Registration Successful!'
        });
        navigate('Authenticated');
    }

    async function cardSubmit(cardToken) {
        setProcessingIndicatorActive(true);
        let organization = {...state.userOrganization};
        try {
            organization = await OrganizationsService.updateOrganization(organization.organization_id, organization);
        } catch (e) {
            console.log('error updating org: ', e);
            setProcessingIndicatorActive(false);
            return
        }

        try {
            let data = await OrganizationsService.registerCard(organization.organization_id, cardToken);
            organization = data.organization;
        } catch (e) {
            console.log('error registering card: ', e);
            setProcessingIndicatorActive(false);
            return
        }

        try {
            await OrganizationsService.initializeSubscription(organization.organization_id);
        } catch (e) {
            console.log('error initializing subscription: ', e);
            setProcessingIndicatorActive(false);
            return
        }

        setProcessingIndicatorActive(false);

        setWarning({
            confirmAction() {
            },
            confirmLabel: 'Continue',
            active: true,
            warningMessage: 'Registration Successful!'
        });
        navigate('Authenticated');
    }

    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;

        if (width > 755) {
            setCompanyInfoChunkSize(3);
        } else if (width > 500) {
            setCompanyInfoChunkSize(2);
        } else {
            setCompanyInfoChunkSize(1);
        }

        if (width > 550 && state.hasPassword) {
            setInviteFieldChunkSize(2);
        } else {
            setInviteFieldChunkSize(1);
        }

    };

    useEffect(() => {
        const getUserInvite = async () => {
            const data = await UserInvitesService.getUserInvite(inviteCode);

            setState(prevState => {
                return {...prevState, hasPassword: data.hasPassword, userOrganization: data.userOrganization};
            });

        };

        getUserInvite();
    }, [props.route]);

    useEffect(() => {
        if (state.userOrganization.organization_id && props.auth.initialized) {
            if (state.userOrganization.user_id === props.auth?.user?.user_id && state.userOrganization.user_organization_accepted_timestamp) {
                if (state.currentSectionIndex === 0) {
                    incrementCurrentSectionIndex();
                }
            } else if (props.auth.user.user_id) {
                const logout = async () => {
                    await AuthService.logout();
                    await AsyncStorage.removeItem('authToken');
                    await AsyncStorage.removeItem('organizationId');
                    await AsyncStorage.removeItem('roleId');
                    resetState();
                };
                logout();
            }
        }

    }, [props.auth?.user?.user_id, props.auth.initialized, state.userOrganization.user_id, state.userOrganization.organization_id, state.userOrganization.user_organization_accepted_timestamp, state.currentSectionIndex])

    useEffect(() => {

        const getStates = async () => {
            const statesData = await LocationsService.getStates();
            setStates(statesData);
        };

        const getSubscriptionTypes = async () => {
            const subscriptionTypesData = await SubscriptionTypesService.getSubscriptionTypes(1);
            setSubscriptionTypes(subscriptionTypesData);
        };
        getStates();
        getSubscriptionTypes();
    }, []);

    return (
        <View style={basicStyles.flexScale} onLayout={handleLayout}>
            <UnauthenticatedHeader
                onLayout={({nativeEvent: {layout: {x, y, width, height}}}) => {
                    if (!header1Height) {
                        setHeader1Height(prevHeaderHeight => prevHeaderHeight + height);
                    }
                }}
            />
            {
                state.userOrganization.user_id && state.userOrganization.organization_id ?
                    <>
                        {
                            state.userOrganization.role_id == CLIENT_ACCOUNT_OWNER_ROLE_ID ?
                                <AccountSetupHeader
                                    label="New Account Setup"
                                    items={[
                                        'Account Setup',
                                        'Company Profile',
                                        'Plan Selection',
                                        'Payment',
                                    ]}
                                    currentIndex={state.currentSectionIndex}
                                    onLayout={({nativeEvent: {layout: {x, y, width, height}}}) => {
                                        if (!header2Height) {
                                            setHeader2Height(prevHeaderHeight => prevHeaderHeight + height);
                                        }
                                    }}
                                />
                                :
                                <View style={[basicStyles.accountSetupInfoBar, styles.accountInvitationHeader]}
                                      onLayout={({nativeEvent: {layout: {x, y, width, height}}}) => {
                                          if (!header2Height) {
                                              setHeader2Height(prevHeaderHeight => prevHeaderHeight + height);
                                          }
                                      }}
                                >
                                    <AppText style={{color: 'white', fontSize: 22, fontFamily: 'SourceSansPro-Light'}}>
                                        Account Invitation From
                                    </AppText>
                                    <Avatar
                                        width={35}
                                        source={{uri: state.userOrganization.organization_thumbnail ? AVATAR_PATH_ORG + state.userOrganization.organization_thumbnail : AVATAR_DEFAULT}}
                                        style={{marginRight: 10, marginLeft: 10}}
                                    />
                                    <AppText style={{color: 'white', fontSize: 22, fontFamily: 'SourceSansPro-SemiBold'}}>
                                        {state.userOrganization.organization_title}
                                    </AppText>
                                </View>
                        }
                        <ScrollView>
                            <View style={[
                                basicStyles.flexScale,
                                basicStyles.flexCenterContent,
                                {
                                    minHeight: getScreenMaxHeight(),
                                },
                                styles.contentContainer
                            ]}>
                                {
                                    [
                                        <>
                                            {
                                                state.hasPassword
                                                    ?
                                                    <>
                                                        <AppText style={styles.heading}>
                                                            {
                                                                state.userOrganization.role_id == CLIENT_ACCOUNT_OWNER_ROLE_ID ?
                                                                    'Login to setup your new Organization'
                                                                    :
                                                                    'Add a New Company to Your Account'
                                                            }
                                                        </AppText>
                                                    </>
                                                    :
                                                    <>
                                                        <AppText style={styles.heading}>
                                                            Create Your Account
                                                        </AppText>
                                                        <AppText style={styles.subHeading}>
                                                            This will be used to log in and access the Fluid Local application
                                                        </AppText>
                                                    </>
                                            }
                                            <AppText style={{marginTop: 22, height: 18, color: '#990000'}}>
                                                {state.errorMessage ? state.errorMessage : ''}
                                            </AppText>
                                            <FieldSet
                                                style={{marginTop: 10, width: '100%', maxWidth: 663}}
                                                chunkSize={inviteFieldChunkSize}
                                                fields={fields}
                                                footer={(
                                                    <View style={[basicStyles.flexCenterContent, {paddingTop: 30, width: '100%'}]}>
                                                        <AppButton
                                                            label={state.hasPassword ? 'Accept Invitation' : 'Next'}
                                                            action={() => acceptInvite()}
                                                            style={styles.footerButton}
                                                        />
                                                        {
                                                            state.hasPassword ?
                                                                <TouchView
                                                                    style={{marginTop: 10}}
                                                                    action={() => navigate('PasswordReset', {
                                                                            redirectScreen: 'AcceptInvite',
                                                                            redirectParams: {
                                                                                inviteCode
                                                                        }
                                                                    })}
                                                                >
                                                                    <AppText style={{fontSize: 12, color: '#CCCCCC'}}>Forgot password?</AppText>
                                                                </TouchView>
                                                                : null
                                                        }
                                                    </View>
                                                )}
                                            />
                                        </>,
                                        <>
                                            <AppText style={styles.heading}>
                                                Enter your Company Information
                                            </AppText>
                                            <AppText style={styles.subHeading}>
                                                This is for your flagship location. Additional Stores can be added within the application
                                            </AppText>
                                            <AppText style={{marginTop: 22, height: 18, color: '#990000'}}>
                                                {state.errorMessage ? state.errorMessage : ''}
                                            </AppText>
                                            <FieldSet
                                                style={{marginTop: 10, width: '100%', maxWidth: 900}}
                                                chunkSize={companyInfoChunkSize}
                                                fields={[
                                                    {
                                                        type: 'text',
                                                        label: 'Organization Title',
                                                        onChangeText: value => updateUserOrganization('organization_title', value),
                                                        value: state.userOrganization.organization_title || '',
                                                    },
                                                    {
                                                        type: 'phone',
                                                        label: 'Phone',
                                                        onChangeText: value => updateUserOrganization('organization_phone', value),
                                                        value: state.userOrganization.organization_phone || '',
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'Business Email',
                                                        onChangeText: value => updateUserOrganization('organization_email', value),
                                                        value: state.userOrganization.organization_email || '',
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'Website',
                                                        onChangeText: value => updateUserOrganization('organization_website', value),
                                                        value: state.userOrganization.organization_website || '',
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'Address 1',
                                                        onChangeText: value => updateUserOrganization('address_street_1', value),
                                                        value: state.userOrganization.address_street_1 || '',
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'Address 2',
                                                        onChangeText: value => updateUserOrganization('address_street_2', value),
                                                        value: state.userOrganization.address_street_2 || '',
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'City',
                                                        onChangeText: value => updateUserOrganization('address_city', value),
                                                        value: state.userOrganization.address_city || '',
                                                    },
                                                    {
                                                        type: 'text',
                                                        label: 'Zip',
                                                        onChangeText: value => updateUserOrganization('address_zip', value),
                                                        value: state.userOrganization.address_zip || '',
                                                    },
                                                    {
                                                        type: 'picker',
                                                        label: 'State',
                                                        onValueChange: value => updateUserOrganization('state_id', value),
                                                        selectedValue: state.userOrganization.state_id || '',
                                                        items: states,
                                                        labelExtractor: 'state_name',
                                                        valueExtractor: 'state_id',
                                                    },
                                                ]}
                                                footer={(
                                                    <View style={[basicStyles.flexCenterContent, {paddingTop: 30, width: '100%'}]}>
                                                        <AppButton
                                                            label={'Next'}
                                                            action={() => incrementCurrentSectionIndex()}
                                                            style={styles.footerButton}
                                                        />
                                                    </View>
                                                )}
                                            />
                                        </>,
                                        <>
                                            <AppText style={styles.heading}>
                                                Select a Plan Below
                                            </AppText>
                                            <AppText style={styles.subHeading}>
                                                Plans can be upgraded any time in your settings
                                            </AppText>
                                            <AppText style={{marginTop: 22, height: 18, color: '#990000'}}>
                                                {state.errorMessage ? state.errorMessage : ''}
                                            </AppText>
                                            <SubscriptionSelectorsContainer>
                                                <SubscriptionSelector
                                                    subscriptionType={{
                                                        subscription_type_title: 'Fluid Local Free',
                                                        subscription_type_cents: '0',
                                                        subscription_type_max_users: 1,
                                                        subscription_type_features: FREE_PLAN_FEATURES
                                                    }}
                                                    active={!state.userOrganization.subscription_type_id}
                                                    onSelect={() => updateUserOrganization('subscription_type_id', null)}
                                                    onDeselect={() => updateUserOrganization('subscription_type_id', null)}
                                                    onConfirm={() => {
                                                        freeSubmit();
                                                    }}
                                                />
                                                {
                                                    subscriptionTypes.map((subscriptionType) => {
                                                        return (
                                                            <SubscriptionSelector
                                                                key={subscriptionType.subscription_type_id}
                                                                active={state.userOrganization.subscription_type_id === subscriptionType.subscription_type_id}
                                                                subscriptionType={subscriptionType}
                                                                onSelect={() => updateUserOrganization('subscription_type_id', subscriptionType.subscription_type_id)}
                                                                onDeselect={() => updateUserOrganization('subscription_type_id', null)}
                                                                onConfirm={() => {
                                                                    incrementCurrentSectionIndex();
                                                                }}
                                                            />
                                                        );
                                                    })
                                                }
                                            </SubscriptionSelectorsContainer>
                                        </>,
                                        <>
                                            <AppText style={styles.heading}>
                                                Subscription Payment
                                            </AppText>
                                            <AppText style={styles.subHeading}>
                                                Plans can be upgraded any time in your settings
                                            </AppText>
                                            <AppText style={{marginTop: 22, height: 18, color: '#990000'}}>
                                                {state.errorMessage ? state.errorMessage : ''}
                                            </AppText>
                                            <StripePaymentSubscription
                                                subscriptionTypes={subscriptionTypes}
                                                selectedSubscriptionTypeId={state.userOrganization.subscription_type_id}
                                                onUpdateSubscriptionType={value => updateUserOrganization('subscription_type_id', value)}
                                                containerStyle={{width: '100%', maxWidth: 1200}}
                                                onSubmit={cardSubmit}
                                                coupon={state.userOrganization.appliedCoupon}
                                                freeTrialDays={state.userOrganization.organization_free_trial_active ? state.userOrganization.organization_free_trial_days : 0}
                                            />
                                        </>,
                                    ][state.currentSectionIndex]
                                }
                            </View>
                        </ScrollView>
                    </>
                    : null
            }
        </View>
    );
}


const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};


const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setToken,
        resetState,
        setUser,
        setOrganization,
        setOrganizationRoleId,
        setWarning,
        setProcessingIndicatorActive,
        setPermissions
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetup);

const styles = EStyleSheet.create({
    heading: {
        color: '#0b2774',
        fontSize: 18,
        fontFamily: 'SourceSansPro-SemiBold',
    },
    subHeading: {
        color: '#0b2774',
        fontSize: 18,
        fontFamily: 'SourceSansPro-Light',
    },
    subscriptionTypesContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: 1200,
        width: '100%',
    },
    subscriptionTypeContainer: {
        flex: 1,
        marginRight: 30,
    },
    subscriptionTypeText: {
        color: '#000000',
    },
    selectedSubscriptionTypeText: {
        color: '#FFFFFF',
    },
    subscriptionTypeTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    subscriptionTypeTitle: {
        fontFamily: 'SourceSansPro-Light',
        fontSize: 28,
    },
    subscriptionTypePricePerMonth: {
        fontFamily: 'SourceSansPro-Bold',
        fontSize: 89,
        marginBottom: 10,
    },
    subscriptionTypeContentSectionWrapper: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 40,
        alignItems: 'flex-start',
    },
    priceHeader: {
        flexDirection: 'column'
    },
    subscriptionTypePerMonth: {
        fontFamily: 'SourceSansPro-SemiBold',
        opacity: .4,
    },
    subscriptionTypeFeaturesHeaderContainer: {
        borderTopWidth: 1,
        borderBottomWidth: 1,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 20,
        paddingRight: 20,
        width: '100%',
        marginTop: 50,
        marginBottom: 25,
    },
    subscriptionTypeFeaturesHeaderText: {
        fontFamily: 'SourceSansPro-Bold',
        fontSize: 20,
    },
    subscriptionTypeFeatureDot: {
        fontSize: 28,
        position: 'relative',
        bottom: 2,
        marginRight: 10,
    },
    subscriptionTypeButtonsSectionWrapper: {
        borderTopWidth: 1,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 25,
        paddingRight: 25,
        flexDirection: 'row',
    },
    contentContainer: {
        backgroundColor: '#f8fafd',
        flex: 1,
        padding: 60,
    },
    accountInvitationHeader: {
        flexWrap: 'wrap',
        paddingRight: 15,
        paddingLeft: 15
    },
    '@media (max-width: 1050)': {
        subscriptionTypesContainer: {
            flexDirection: 'column'
        },
        priceHeader: {
            flexDirection: 'row',
            width: '100%'
        },
        subscriptionTypeTitleContainer: {
            width: '50%',
            justifyContent: 'center'
        },
        subscriptionTypeValueContainer: {
            width: '50%',
            textAlign: 'center',
            borderLeftWidth: 1,
        },
        subscriptionTypeContainer: {
            marginRight: 0,
            marginBottom: 30,
        },
        subscriptionTypeButtonsSectionWrapper: {
            marginTop: 20,
        }
    },
    '@media (max-width: 768)': {
        contentContainer: {
            paddingRight: 30,
            paddingLeft: 30
        },
        subHeading: {
            textAlign: 'center'
        }
    },
    '@media (max-width: 650)': {
        subscriptionTypePricePerMonth: {
            fontSize: 70
        },
    },
    '@media (max-width: 500)': {
        priceHeader: {
            flexDirection: 'column'
        },
        subscriptionTypeValueContainer: {
            borderLeftWidth: 0,
            textAlign: 'left',
            width: '100%',
        },
        subscriptionTypeTitleContainer: {
            justifyContent: 'flex-start',
            width: '100%',
        },
    },
    '@media (max-width: 400)': {
        footerButton: {
            width: '100%'
        }
    }
});
